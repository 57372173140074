import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Kichul Kang | Purdue CS', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Kichul Kang.',
  subtitle: 'I am a Computer Science student at Purdue University.',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile-cropped.jpg',
  paragraphOne: 'I am an undergraduate student studying Computer Science with minors in Economics and Mathematics. My expected graduation date is May of 2023.',
  paragraphTwo: 'I have a passion for learning new things. ',
  //paragraphThree: 'I am ',
  resume: 'https://www.overleaf.com/read/nrtbcsnhdzms', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  // {
  //   id: nanoid(),
  //   img: 'portfolio.png',
  //   title: 'This portfolio website',
  //   info: 'Built with Gatsby and hosted with Netflify.',
  //   info2: '',
  //   url: 'https://www.kichulkang.com/', //if no url, the button will not show up
  //   repo: 'hello', // if no repo, the button will not show up
  // },
  {
    id: nanoid(),
    img: 'chessStats.png',
    title: 'Chess Stats',
    info: 'Created with Angular, Django, MySQL',
    info2: 'A statistics reporting website allowing users to submit and view data from completed chess matches. ',
    info3: 'Implements a Django server to communicate with the database.',
    url: '', // if no url, the button will not show up
    repo: 'https://github.com/kckang1103/ChessStats', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'scrapeGoats.png',
    title: 'Scrape Goats',
    info: 'Created with React Native, Firebase, Flask',
    info2: 'An application that scrapes tweets containing a given query from the past 10 years, and performs sentiment analysis on each tweet before filling a chart with the tweets as data points. ',
    info3: 'Utilizes Google\'s Cloud Natural Language API and Vision API.',
    url: '', // if no url, the button will not show up
    repo: 'https://github.com/kckang1103/ScrapeGoats', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'pineappleMusic.png',
    title: 'Pineapple Music',
    info: 'Created with React, Firebase, Nodejs',
    info2: 'A web application that allows group listening with realtime group messaging and customizable bookmarks to skip songs to specific points. ',
    info3: 'Implements Firebase\'s fireStorage to provide real-time features.',
    url: '', // if no url, the button will not show up
    repo: 'https://github.com/kckang1103/Pineapple-Music', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'gymbook.PNG',
    title: 'Gym Book',
    info: 'Created with MongoDB, Apollo, Nodejs, Reactjs, GraphQL',
    info2: 'A full stack social media application that allows users to share their daily workout experience/routine. ',
    info3: 'Implements a GraphQL server that communicates to MongoDB database using Node and Apollo.',
    url: 'https://gymbook.netlify.app/', // if no url, the button will not show up
    repo: 'https://github.com/kckang1103/GymBook', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'moodswing.PNG',
    title: 'Mood Swing',
    info: 'Created with HTML, CSS, JavaScript, Nodejs',
    info2: 'A web application that asks for the user\'s current emotional state and creates a new playlist in the user\'s Spotify account.',
    info3: 'Quantitates the user\'s emotions and fetches song lists by utilizing Spotify API.',
    url: '', // if no url, the button will not show up
    repo: 'https://github.com/kckang1103/MoodSwing', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'midi.PNG',
    title: 'MIDI Library',
    info: 'Created with C',
    info2: 'The library parses .mid files in the selected folder into a tree structure.',
    info3: 'Allows the user to alter attributes of the selected song, such as warping time, remapping instruments, and changing octaves. ',
    url: '', // if no url, the button will not show up
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'airline.PNG',
    title: 'Airline Reservation System',
    info:  'Created with Java (Swing)',
    info2: 'The system takes each client on a thread and concurrently updates the status of the availability for each airline.',
    info3: 'Utilizes graphical user interface, Swing, to assist the user in navigating.',
    url: '', // if no url, the button will not show up
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to work with me? Get in touch!',
  btn: 'Say Hello',
  email: 'kang377@purdue.edu',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kckang1103',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kichul-kang-79a112191/',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/kichul_kang/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to true to enable the GitHub stars/fork buttons
};
